import { useEffect, useState, useRef } from 'react';
import { Spinner, Modal } from 'react-bootstrap'
import { useLocation } from "react-router-dom";
import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, doc, getDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import Moment from 'react-moment';

const firebaseConfig = {
    apiKey: "AIzaSyDTZEholr4Clx7LfxGBqFoXoxOc6HG4tmA",
    authDomain: "public-sector-day-aws.firebaseapp.com",
    projectId: "public-sector-day-aws",
    storageBucket: "public-sector-day-aws.appspot.com",
    messagingSenderId: "461842995464",
    appId: "1:461842995464:web:335c44d1e842e0b84274bd",
    measurementId: "G-BM5HB129DD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


const db = getFirestore(app);
// const storage = getStorage(app);
const functions = getFunctions(app, "asia-southeast2");
const auth = getAuth(app);

function App() {
    const [userData, setUserData] = useState(null);
    const [areaData, setAreaData] = useState({ name: "" })
    const [loadingArea, setLoadingArea] = useState(false)
    const [disableInput, setDisableInput] = useState(false)
    const [tempUserId, setTempUserId] = useState("");
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [modalError, setModalError] = useState(false)
    const inputRef = useRef();

    useEffect(() => {
        if (window.location.hostname === "localhost") {
            connectFirestoreEmulator(db, '127.0.0.1', 8080);
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);  
            connectAuthEmulator(auth, "http://127.0.0.1:9099");
        }
    }, [])

    const search = useLocation().search;
    const areaId = new URLSearchParams(search).get("areaId");

    const handleClose = (event, reason) => {
        setUserData(null)
        setTempUserId("")
        setOpen(false);
        setModalError(false)
        window.location.reload();
    };

    const handleChange = (event) => {
        setTempUserId(event.target.value)
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.currentTarget);
        var qrCode = data.get('formId');
        var areaIdUser = areaId;
        // var checkUserOnSite = firebase.app().functions('asia-southeast2').httpsCallable("checkUserOnsite");
        try {
                
            const checkUserOnSite = httpsCallable(functions, "checkUserOnsite");
            const responseData = await checkUserOnSite({
                qrCode: qrCode,
                areaId: areaIdUser
            });
            console.log(responseData);
            if (responseData.data.success == true) {
                setUserData(responseData.data.data)    
                setOpen(true);

                setTimeout(() => {
                    handleClose()
                }, 3000)
            } else {
                setModalError(true)

                setTimeout(() => {
                    handleClose()
                }, 3000)
            }

        } catch (error) {
            console.error("error check user on site: ", error);
        }
        setTempUserId("")
        setLoading(false)
    };

    const fetchDataArea = async () => {
        setLoadingArea(true)
        try {
            const areaRef = doc(db, "restrictionArea", areaId)
            const areaSnap = await getDoc(areaRef)
    
            if (areaSnap.exists()) {
                const tempAreaData = areaSnap.data();
                setAreaData(tempAreaData);
            } else {
                setAreaData("")
                return;
            }
            setLoadingArea(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if(areaId) {
            fetchDataArea()
        } else {
            setLoadingArea(false)
        }
    }, [areaId])

    useEffect(() => {
        if (modalError) {
            setDisableInput(true)
        } else {
            setDisableInput(false)
        }
    }, [modalError, disableInput])

    return (
        <>
            {
                open ?
                    <div className="vh-100">
                        <div className="h-100 d-flex justify-content-center align-items-center px-0 w-100 flex-column">
                            <p className="text-center text-user mb-1">Welcome</p>
                            <p className="text-center text-user">{userData.printedName ? userData.printedName : userData.name}</p>
                        </div>
                    </div>
                    :
                    <div className="vh-100">
                        <div className="h-100 d-flex justify-content-center align-items-center px-0 w-100 flex-column">
                            {
                                loadingArea ? 
                                <div className="text-center mb-4 d-flex align-items-center">
                                    <Spinner animation="border" variant="light" className="me-2" />
                                    <div className="text-white" style={{fontSize: "16px"}}>Loading...</div>
                                </div>
                                :
                                <>
                                    {
                                        areaData.name === "" ?
                                        <div className="row g-0 justify-content-center">
                                            <div className="col-8">
                                                <img className="w-100" src="/images/psd-logo.jpg" alt="" />
                                            </div>
                                        </div>
                                        :
                                        <form className="w-100" onSubmit={handleSubmit}>
                                             <div className="row g-0 justify-content-center mb-2">
                                                <div className="col-6">
                                                    <img className="w-100" src="/images/psd-logo.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center w-100 gx-0">
                                                <div className="col-12 text-center">
                                                    <>
                                                        <p className="form-label text-center w-100 mb-2 text-breakout-room">{areaData.name || "Public Sector Day"}</p>
                                                    </>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center w-100 gx-0 mb-3">
                                                <div className="col-xl-3 col-lg-4 col-8 text-center">
                                                    <input
                                                        disabled={disableInput ? true : false}
                                                        onBlur={({ target }) => target.focus()}
                                                        ref={inputRef}
                                                        name="formId"
                                                        id="formId"
                                                        required
                                                        autoFocus
                                                        type="text"
                                                        className="form-control w-100 mx-auto"
                                                        value={tempUserId}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center w-100 gx-0">
                                                <div className="col-xl-3 col-lg-4 col-8 text-center">
                                                    <button disabled={loading ? true : false} className="btn btn-warning w-100 btn-submit" type="submit">
                                                        {loading ? "Loading..." : "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </>
                            }
                        </div>
                    </div>
            }

            <Modal show={modalError} centered onHide={handleClose}>
                <Modal.Body>
                    <div className="py-4">
                        <div className="text-center mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="red" className="bi bi-x-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                        <h1 className="text-center text-danger mb-4">You are not eligible to enter this area</h1>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default App;
